/* General styles */

@import 'style/breakpoints';

body {
  margin: 0;
  font-family: "Muli", sans-serif;
}

input,
textarea,
button {
  font-family: inherit;
}

button:focus {
  outline: none;
}

a {
  color: #374b5b;
}

hr {
  border: 0;
  height: 1px;
  background: rgba(90, 122, 148, 0.2);
}

/* Page-specific styling */
@import 'pages/map/Map';
@import 'pages/Welcome';
